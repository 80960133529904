import { StaticImage } from "gatsby-plugin-image";
import React, { Component } from "react";

// css
import * as styles from "./ReferralQuiz.module.css";

class ResultWaiting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    setTimeout(() => {
      this.props.handleNextStep(4);
    }, 3000);
  }

  render() {
    return (
      <div className="bgGray">
        <div className="container">
          <div
            className={`row align-items-center ${
              this.props.appForDxStatus
                ? styles.conWithDxHeight
                : styles.conHeight
            }`}
          >
            <div className="row ">
              <div className="col-md-4 offset-md-4">
                <div className={`pt-4`}>
                  <div className="mt-3 text-center">
                    <lottie-player
                      src="https://assets8.lottiefiles.com/packages/lf20_mkzxw4th.json"
                      background="transparent"
                      speed="1"
                      style={{
                        width: "200px",
                        height: "200px",
                        margin: "auto",
                      }}
                      loop
                      // controls
                      autoplay
                    ></lottie-player>

                    <h3 className={styles.result_heading}>Great!</h3>

                    <p className={styles.result_p}>
                      We are creating your digital twin and a personalised
                      program for you...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.logoConHeight}>
            <div className="col-12 d-flex justify-content-center">
              <div className={styles.logoImg}>
                <StaticImage
                  src={"../../images/logo-v1.png"}
                  placeholder="blurred"
                  alt="twinLogo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultWaiting;
