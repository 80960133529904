import React, { Component } from "react";
import axios from "axios";

// config
import config from "../../config";

// Components
import Steps from "../DiabetesQuiz/Steps";

// css

import * as styles from "./ReferralQuiz.module.css";

// images
import { StaticImage } from "gatsby-plugin-image";

class ReferralQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      questions: [],
      step: 0,
      progress: 0,
    };
  }

  getInitialData = async () => {
    const options = {
      method: "get",
      url: config.DX_API_BASE_URL + "/api/marketing/quiz/questions",
      headers: {
        Authorization: `Bearer ${config.JWT_TOKEN}`,
      },
    };

    try {
      const res = await axios(options);
      const results = res.data.results;
      // transform
      results.map(question => {
        question.Options.map((option, Index) => {
          option.Index = Index;
          option.selected = false;
          return option;
        });
        return question;
      });
      this.setState({
        loading: true,
        questions: results,
      });
    } catch (error) {
      console.error("quiz error", error);
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.getInitialData();
    }, 500);
  }

  answerSelect = values => {
    const { questions, step } = this.state;
    const activeQuestion = questions[step];

    activeQuestion.Options.map(option => {
      if (option.Index === values.Index) {
        option.selected = true;
      } else {
        option.selected = false;
      }
      return option;
    });

    this.setState({ activeQuestion });

    let nextStep = step;
    if (nextStep + 1 < questions.length) {
      nextStep = step + 1;
    } else {
      let result = {};
      for (let question of questions) {
        const found = question.Options.find(item => item.selected === true);
        if (question.QuestionId === "1") {
          result.female = found.Value;
        } else if (question.QuestionId === "2") {
          result.age = found.Value;
        } else if (question.QuestionId === "3") {
          result.weight = found.Value;
        } else if (question.QuestionId === "4") {
          result.numMeds = found.Value;
        } else if (question.QuestionId === "5") {
          result.sleepHours = found.Value;
        } else if (question.QuestionId === "6") {
          result.hba1c = found.Value;
        }
      }
      this.setState({
        progress: Math.round((this.state.step + 1 / questions.length) * 100),
      });

      setTimeout(() => {
        this.props.submitQuiz(result);
      }, 300);

      return;
    }
    this.setState({
      progress: Math.round((nextStep / questions.length) * 100),
    });

    setTimeout(() => {
      this.setState({ step: nextStep });
    }, 400);
  };

  handleBackStep = () => {
    this.setState({
      progress: Math.round(
        ((this.state.step - 1) / this.state.questions.length) * 100
      ),
    });
    this.setState({ step: this.state.step - 1 });
  };

  render() {
    const { loading, questions, step } = this.state;
    const activeQuestion = questions[step];

    return !loading ? (
      <QuestionLoader {...this.props} />
    ) : (
      <div className="bgLightgray">
        <div className="container twinContainer">
          <div
            className={`${
              this.props.appForDxStatus
                ? styles.conWithDxHeight
                : styles.conHeight
            }`}
          >
            <div className={`row`}>
              <div className="col-12">
                <div className="mt-3 mb-3 text-center">
                  <Steps
                    handleBackStep={
                      step === 0
                        ? () => this.props.handleNextStep(0)
                        : this.handleBackStep
                    }
                    progress={this.state.progress}
                    totalStep={questions.length}
                    currentStep={step}
                    goFirstStep={this.props.handleNextStep}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 px-md-0 offset-md-3">
                <div>
                  <div className={`mt-4 mb-5 ${styles.question}`}>
                    {activeQuestion["Question"]}
                  </div>
                  {activeQuestion.Options.map((option, index) => {
                    return (
                      <div
                        key={index}
                        className={`d-flex align-items-center ${
                          styles.option
                        } ${option.selected ? styles.option_selected : ""}`}
                        onClick={() => this.answerSelect(option)}
                        role="presentation"
                      >
                        {step === 0 ? (
                          index === 0 ? (
                            <span className={styles.maleImg}>
                              <StaticImage
                                src={"../../images/quiz/male.svg"}
                                placeholder="blurred"
                                alt="male"
                              />
                            </span>
                          ) : (
                            <span className={styles.maleImg}>
                              <StaticImage
                                src={"../../images/quiz/female.svg"}
                                placeholder="blurred"
                                alt="male"
                              />
                            </span>
                          )
                        ) : (
                          ""
                        )}

                        <p> {option.Option} </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className={`${styles.logoConHeight}`}>
            <div className={`row ${styles.logoConHeight}`}>
              <div className="col-12 d-flex justify-content-center">
                <div className={styles.logoImg}>
                  <StaticImage
                    src={"../../images/logo-v1.png"}
                    placeholder="blurred"
                    alt="twinLogo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const QuestionLoader = props => {
  return (
    <div className="bgGray">
      <div className="container twinContainer">
        <div
          className={`row align-items-center ${
            props.appForDxStatus ? styles.conWithDxHeight : styles.conHeight
          }`}
        >
          <div className="row">
            <div className="col-md-4 offset-md-4 col-12">
              <div className="mt-6 text-center">
                <lottie-player
                  src="https://assets8.lottiefiles.com/packages/lf20_mkzxw4th.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "200px", height: "200px", margin: "auto" }}
                  loop
                  // controls
                  autoplay
                ></lottie-player>
                <div>
                  <h3 className={styles.heading_text}>
                    PREPARING YOUR QUESTION SET...
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <div className={`row ${styles.logoConHeight}`}>
          <div className="col-12 d-flex justify-content-center">
            <div className={styles.logoImg}>
              <StaticImage
                src={"../../images/logo-v1.png"}
                placeholder="blurred"
                alt="twinLogo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralQuiz;
