import React from "react";

// css
import * as styles from "./ReferralQuiz.module.css";

// images
import { StaticImage } from "gatsby-plugin-image";
import { sendToCT } from "../../services/Clevertap";

const QuizHome = props => {
  const handleButtonClick = () => {
    sendToCT("referral_quiz_initiated", {
      action: "on clicking getting started",
    });
    props.handleNextStep(2);
  };

  return (
    <div className={`bgGray`}>
      <div className={`container`}>
        <div
          className={`row pt-5 justify-content-center align-items-center ${
            props.appForDxStatus ? styles.conWithDxHeight : styles.conHeight
          }`}
        >
          <div className={`row `}>
            <div className="col-12">
              <div className="col-md-4 offset-md-4 pb-4 col-12 px-4 px-md-0">
                <p className={`text-center ${styles.text1}`}>
                  Welcome to your reversal journey!
                </p>
                <p className={`text-center mt-2 ${styles.text1}`}>
                  Answer these 6 questions for twin to craft a personalised
                  reversal plan to heal your damaged metabolism.
                </p>
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className={`col-12 ${styles.belowTxt2}`}>
              <div className="col-md-4 px-5 offset-md-4 col-12">
                <p className={styles.text1}>
                  6500+ members have normalised their blood sugar & stopped all
                  medications.
                </p>
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className={`col-12`}>
              <div className="col-md-4 offset-md-4 col-12 px-5 px-md-0">
                <p className={styles.text2}>
                  Tap the button below to get your reversal date
                </p>
              </div>
              <div className="col-md-4"></div>
            </div>

            <div className={`col-12 ${styles.ctaBtn}`}>
              <div
                role={"presentation"}
                onClick={handleButtonClick}
                className="col-md-4 offset-md-4 px-md-5 col-12 cursorPointer"
              >
                <div className="gradientBtnWithArrow pt-2 pb-2">
                  <p className="pt-3 pb-3"> Predict my reversal date </p>
                  <span
                    className={`mediumIcon icon-arrow-forward ${styles.whiteFontArrow}`}
                  />
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
        <div className={`row align-items-center ${styles.connHeight}`}>
          <div className="col-12 d-flex justify-content-center">
            <div className={styles.logoImg}>
              <StaticImage
                src={"../../images/logo-v1.png"}
                placeholder="blurred"
                alt="twinLogo"
              />
            </div>
          </div>
        </div>
        <div className={`row ${styles.connnHeight}`}>
          <div className="col-12">
            <p className={styles.belowTxt}>
              *The predicted date is an estimate. Actual results may vary based
              on compliance with the program.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizHome;
