import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// app header
import AppHeader from "../components/AppHeader/AppHeader";

// config
import config from "../config";

// components
import QuizForm from "../components/ReferralQuiz/QuizForm";
import QuizHome from "../components/ReferralQuiz/QuizHome";
import ReferralQuiz from "../components/ReferralQuiz/ReferralQuiz";

import ResultWaiting from "../components/ReferralQuiz/ResultWaiting";
import QuizResult from "../components/ReferralQuiz/QuizResult";

class ReferralQuizPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      questionResult: {},
      quizResult: {},
      user: {},
      fetching: false,
      serverError: "",
      clintInfo: {},
      clientId: "",
      referralCode: "",
      refererInfo: {},
    };
  }

  getInitialData = async referralCode => {
    const options = {
      method: "get",
      url: config.DX_API_BASE_URL + "/api/referrals/" + referralCode,
    };

    try {
      const response = await axios(options);
      this.setState({ refererInfo: response.data.results, fetching: true });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({ serverError: "Try again", fetching: true });
    }
  };

  handleNextStep = nextStep => {
    this.setState({ step: nextStep });
  };

  submitQuiz = async values => {
    this.handleNextStep(2);

    const options = {
      method: "get",
      // url: config.DX_API_BASE_URL + "/api/marketing/reversal",
      url: "https://dx-backend-prd.engg.twinsdigital.com/api/marketing/reversal",
      headers: {
        Authorization: `Bearer ${config.JWT_TOKEN}`,
      },
      params: values,
    };

    try {
      const res = await axios(options);
      const results = res.data.results;

      this.setState({
        quizResult: results[0] || {},
        questionResult: values,
      });

      setTimeout(() => {
        this.handleNextStep(3);
      }, 3000);
    } catch (error) {
      console.error("quiz error", error);
    }

    // setTimeout(() => {
    // 	this.handleNextStep(1);
    // }, 400);
  };

  handleUser = user => {
    this.setState({ user });
  };

  componentDidMount() {
    const { referralCode } = queryString.parse(this.props.location.search);

    if (referralCode) {
      this.setState({ referralCode });
      this.getInitialData(referralCode);
    }
  }

  render() {
    const {
      step,
      questionResult,
      quizResult,
      user,
      refererInfo,
      referralCode,
      fetching,
    } = this.state;
    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div>
        <Seo
          title="Diabetes Quiz | Diabetes Reversal Program | Twin"
          description="Diabetes Quiz. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
        />
        {appHeader === "mobile" && (
          <section>
            <AppHeader text="REFERRAL QUIZ" isBack={true} />
          </section>
        )}
        <div>
          {step === 0 && (
            <QuizForm
              handleNextStep={this.handleNextStep}
              handleUser={this.handleUser}
              refererInfo={refererInfo}
              referralCode={referralCode}
              fetching={fetching}
              appForDxStatus={appHeader === "mobile"}
            />
          )}

          {step === 1 && (
            <QuizHome
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
            />
          )}

          {step === 2 && (
            <ReferralQuiz
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
              submitQuiz={this.submitQuiz}
            />
          )}

          {step === 3 && (
            <ResultWaiting
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
            />
          )}

          {step === 4 && (
            <>
              <QuizResult
                handleNextStep={this.handleNextStep}
                quizResult={quizResult}
                user={user}
                questionResult={questionResult}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const withContext = props => <ReferralQuizPage {...props} />;

export default withContext;
