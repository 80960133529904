import React from "react";
//images
import { StaticImage } from "gatsby-plugin-image";

//css
import * as styles from "./DiabetesQuiz.module.css";

const QuizBanner = ({ user, quizResult }) => {
  return (
    <div className="bgLightgray">
      <div className="container twinContainer">
        <div className="row">
          <div
            className={`col-12 d-flex justify-content-center ${styles.resultCon}`}
          >
            <StaticImage
              src={"../../images/logo-v1.png"}
              placeholder="blurred"
              alt="twinLogo"
            />
          </div>
          <div className="col-12">
            <div
              className={`col-12 col-md-8 offset-md-2 d-flex justify-content-md-center ${styles.resultConTxt1}`}
            >
              <p className={`${styles.resultConTxt} px-2  px-md-2`}>
                Congratulations,{" "}
                <span className={`${styles.userName}`}>
                  {user && user.name ? user.name : "testing"}!
                </span>
                <br />
                Enter your reversal phase <br></br>in
              </p>
            </div>
            <div className="offset-md-2"></div>
          </div>
          <div className="col-12">
            <div className="col-12 d-flex justify-content-center">
              <span className={styles.days}>
                {(quizResult && Math.floor(quizResult.revTime)) || 39}* days
              </span>
              <div className={styles.curve}>
                <StaticImage
                  src={"../../images/quiz/curve.png"}
                  alt="curve"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
          {/* <div className="col-12 d-flex justify-content-center">
              <span className={styles.resultWarn}>
                here is how your potential reversal graph looks like{" "}
              </span>
            </div> */}
          {/* <div className="col-12 d-flex justify-content-center">
              <span className={styles.chartImg}>
                <StaticImage
                  src={"../../images/quiz/chart.png"}
                  alt="chart"
                  placeholder="blurred"
                />
              </span>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default QuizBanner;
