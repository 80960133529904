import React, { useState, useEffect } from "react";

//utils
import LS from "../../utils/LocalStorageService";
import { sendToCT } from "../../services/Clevertap";
import { sendToLSQ } from "../../services/LeadSquared";

// component
import Inclusion from "../Home/Inclusion/Inclusion";
import SuccessStories from "../Home/SuccessStories/SuccessStories";
import QuizBanner from "../DiabetesQuiz/QuizBanner";
import QuizCta from "../DiabetesQuiz/QuizCta";

const QuizResult = props => {
  console.log("---->>>", props);
  const { user, quizResult } = props;
  const [clientId, setClientId] = useState();
  /*  eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => {
    const lsValue2 = LS.getValue("referreId");
    setClientId(lsValue2.clientId);
    sendToCT("referral_quiz_successful", props.quizResult);
    sendDataLSQ();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const sendDataLSQ = async () => {
    let leadSquaredParams = {
      FirstName: props.user.name,
      Phone: props.user.phone,
      EmailAddress: props.user.email,
      mx_Website_CTA: "Quiz",
      mx_Latest_Source: "New Website",
      mx_quiz_age: parseFloat(props.questionResult.age),
      mx_quiz_gender:
        props.questionResult.female === "true" ? "female" : "male",
      mx_quiz_hba1c: parseFloat(props.questionResult.hba1c),
      mx_quiz_medicine: parseFloat(props.questionResult.numMeds),
      mx_quiz_sleep_hrs: parseFloat(props.questionResult.sleepHours),
      mx_quiz_weight: parseFloat(props.questionResult.weight),
    };

    try {
      await sendToLSQ(leadSquaredParams);
    } catch (error) {
      console.error("quiz server error", error);
    }
  };

  return (
    <>
      <QuizBanner user={user} quizResult={quizResult} />
      <QuizCta
        ctaLink={`/book-pack-referral/?clientId=${clientId}`}
        ctaEvent={"referral_quiz_result_CTA"}
        ctaAction={{ action: "user clicks on book pack CTA" }}
      />
      <SuccessStories
        carouselId="referralQuizSuccessStories"
        bgColor={"bgLightpink"}
        titleText="Numerous people are reversing diabetes with Whole Body Digital Twin™"
      />
      <Inclusion />
      <div
        className="bgLightgray"
        style={{
          fontSize: "13px",
          fontWeight: "400",
          color: "#4C4C4C",
          padding: "0px 18px 18px 18px",
        }}
      >
        <p>
          *T&C: For sustained diabetes reversal we recommend adhering to the
          program for at least 52 weeks for a chance at Diabetes-free life.
        </p>
      </div>
    </>
  );
};

export default QuizResult;
