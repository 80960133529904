import React from "react";

//css
import * as styles from "./DiabetesQuiz.module.css";
import { navigate } from "gatsby";
import { sendToCT } from "../../services/Clevertap";

const QuizCta = props => {
  const navigateTo = () => {
    sendToCT(props.ctaEvent, {
      action: props.ctaAction,
    });
    navigate(props.ctaLink ? props.ctaLink : "#");
  };
  return (
    <div className="secondaryBgColor">
      <div className="container twinContainer">
        <div className={`row ${styles.ctaCon}`}>
          <div className="col-md-5 pb-5 pb-md-0 col-12">
            <span className={styles.ctaConTxt}>
              Ready to kickstart your journey?
            </span>
          </div>
          <div className="col-md-5 col-12 offset-md-2">
            <div role="presentation" onClick={navigateTo}>
              <div className={`gradientBtnWithArrow d-flex`}>
                <p className="pt-3 pb-3 pt-md-3 pt-lg-3 pb-md-3 pb-lg-3">
                  Start my reversal journey
                </p>
                <span
                  className={`mediumIcon icon-arrow-forward ${styles.whiteFontArrow}`}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default QuizCta;
