import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Loader from "react-loader-spinner";

// config
import config from "../../config";

// utils
import LS from "../../utils/LocalStorageService";

// services
import { sendToLSQ } from "../../services/LeadSquared";

// css
import * as styles from "./ReferralQuiz.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { sendToCT } from "../../services/Clevertap";

// images
// import rightArrow from "../../images/quiz//right-arrow-white.svg";
// import logoGray from "../../images/quiz/logo-gray.svg";

const QuizForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const onSubmit = async data => {
    // LeadSquared integration
    let leadSquaredParams = {
      FirstName: data.name,
      Phone: data.phone,
      EmailAddress: data.email,
      mx_Website_CTA: "referral_quiz",
      mx_Latest_Source: "New Website",
    };

    try {
      setLoading(true);
      setServerError("");
      await sendToLSQ(leadSquaredParams);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setServerError("Try again");
      console.error("referral-quiz server error", error);
    }

    const formBody = {
      firstName: data.name,
      lastName: "",
      email: data.email,
      mobileNumber: data.phone,
    };

    const formOptions = {
      method: "post",
      url:
        config.DX_API_BASE_URL +
        "/api/referee/" +
        props.referralCode +
        "/capture",
      data: formBody,
    };

    try {
      const resData = await axios(formOptions);

      // save clientId and referral code of new referre in local storage
      LS.setValue("referreId", { clientId: resData.data.results.clientId });
      LS.setValue("referralCode", { referralCode: props.referralCode });
      props.handleUser(data);
      sendToCT("referral CTA", data);
      props.handleNextStep(1);

      setLoading(true);
    } catch (error) {
      setLoading(false);
      setServerError("The email id provided belongs to an existing member.");
      console.error("Error in Referral Form", error);
    }
  };

  return (
    <div className="bgLightgray">
      <div className="container twinContainer">
        <div
          className={`row align-items-center ${
            props.appForDxStatus ? styles.conWithDxHeight : styles.conHeight
          }`}
        >
          {!props.fetching ? (
            <div className="col-12 d-flex justify-content-center">
              <Loader type="TailSpin" color="#ff7800" height={30} width={30} />
            </div>
          ) : (
            <div className="col-md-6 offset-md-3 col-12 pe-0">
              <div className={`col-12 pt-4`}>
                <div className="col-12">
                  <div className={styles.giftIcon}>
                    <StaticImage
                      src={"../../images/quiz/gift.svg"}
                      alt="git"
                      placeholder="blurred"
                    />
                  </div>
                  <p className={styles.form_heading}>
                    Congratulations! {props.refererInfo.user.name} has gifted
                    you Rs.{props.refererInfo.rewards.operationValue}
                  </p>
                </div>
                <div className="col-md-8 offset-md-2 col-12">
                  <div className={styles.form_p}>
                    Fill up the form below and begin your reversal journey with
                    us!
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="col-md-12 col-12">
                <div className="ps-md-5 pe-md-5">
                  <form
                    name="referralQuiz"
                    onSubmit={handleSubmit(onSubmit)}
                    className={`mt-3`}
                  >
                    <div className={`form-group mb-3 ${styles.formInput}`}>
                      <label htmlFor="name">Full Name* </label>

                      <input
                        className={
                          errors.name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Full Name*"
                        id="name"
                        type="text"
                        {...register("name", {
                          required: "Please enter your name",
                          pattern: {
                            value: /^[a-zA-Z ]+([a-zA-Z ]+)*$/, // eslint-disable-line no-useless-escape
                            message: "Please enter your valid name",
                          },
                          maxLength: {
                            value: 50,
                            message: "Name is too long",
                          },
                        })}
                      />
                      {errors.name && (
                        <span className="invalid-feedback">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div className={`form-group mb-3 ${styles.formInput}`}>
                      <label htmlFor="name">Email* </label>

                      <input
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Email*"
                        id="email"
                        type="email"
                        {...register("email", {
                          required: "Please enter your email",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                            message: "Please enter your valid email",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="invalid-feedback">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                    <div className={`form-group mb-3 ${styles.formInput}`}>
                      <label htmlFor="name">Phone* </label>

                      <input
                        className={`form-control letterSp1 ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        placeholder="Phone Number*"
                        id="phone"
                        type="text"
                        inputMode="numeric"
                        maxLength="10"
                        {...register("phone", {
                          required: "Please enter your mobile number",
                          pattern: {
                            value:
                              /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/,
                            message: "Please enter your valid phone number",
                          },
                          maxLength: {
                            value: 10,
                            message: "Invalid phone number",
                          },
                        })}
                      />
                      {errors.phone && (
                        <span className="invalid-feedback">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-2">
                      <div className="col-12 cursorPointer">
                        <button
                          type="submit"
                          className={`gradientBtnWithArrow w-100 pt-2 pb-2 ${styles.submitBtn}`}
                        >
                          {!loading ? (
                            <>
                              <p className={styles.btntxt}>
                                Show my reversal date{" "}
                              </p>
                              <span
                                className={`mediumIcon icon-arrow-forward ${styles.whiteFontArrow}`}
                              />
                            </>
                          ) : (
                            <span className={"pt-1 pb-1"}>
                              <Loader
                                type="ThreeDots"
                                color="#FFF"
                                height={30}
                                width={30}
                                visible={loading}
                              />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="mt-2 text-center text-danger">
                      {serverError}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-3"></div>
        </div>
        <div className={`row ${styles.logoConHeight}`}>
          <div className="col-12 d-flex justify-content-center">
            <div className={styles.logoImg}>
              <StaticImage
                src={"../../images/logo-v1.png"}
                placeholder="blurred"
                alt="twinLogo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizForm;
