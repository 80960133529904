// extracted by mini-css-extract-plugin
export var Hurry = "ReferralQuiz-module--Hurry--ogYJT";
export var actionContainer = "ReferralQuiz-module--actionContainer--sZD2e";
export var belowTxt = "ReferralQuiz-module--belowTxt--fYk32";
export var belowTxt2 = "ReferralQuiz-module--belowTxt2--bkP+D";
export var btnCon = "ReferralQuiz-module--btnCon--56qwq";
export var btntxt = "ReferralQuiz-module--btntxt--U94m8";
export var chartImg = "ReferralQuiz-module--chartImg--Mlf1n";
export var con = "ReferralQuiz-module--con--o5kk2";
export var conHeight = "ReferralQuiz-module--conHeight--Ojm6n";
export var conWithDxHeight = "ReferralQuiz-module--conWithDxHeight--0p0h-";
export var connHeight = "ReferralQuiz-module--connHeight--o95Qb";
export var connnHeight = "ReferralQuiz-module--connnHeight--sfwY1";
export var ctaBtn = "ReferralQuiz-module--ctaBtn--jYP3O";
export var ctaCon = "ReferralQuiz-module--ctaCon--o7HvM";
export var ctaConTxt = "ReferralQuiz-module--ctaConTxt--f7dWR";
export var curve = "ReferralQuiz-module--curve--pgZcD";
export var days = "ReferralQuiz-module--days--p0k47";
export var designation = "ReferralQuiz-module--designation--QRsEL";
export var designationSecond = "ReferralQuiz-module--designationSecond--FkoVU";
export var details = "ReferralQuiz-module--details--4WNPE";
export var dr1Img = "ReferralQuiz-module--dr1Img--3SN0E";
export var drName = "ReferralQuiz-module--drName--FxUH8";
export var formInput = "ReferralQuiz-module--formInput--xsZ9I";
export var form_heading = "ReferralQuiz-module--form_heading--vvECs";
export var form_p = "ReferralQuiz-module--form_p--trZCP";
export var giftIcon = "ReferralQuiz-module--giftIcon--TqCbB";
export var heading_text = "ReferralQuiz-module--heading_text--zhh8d";
export var logoConHeight = "ReferralQuiz-module--logoConHeight--suy62";
export var logoImg = "ReferralQuiz-module--logoImg--08VN4";
export var maleImg = "ReferralQuiz-module--maleImg--2W9M8";
export var option = "ReferralQuiz-module--option--iLmbF";
export var option_selected = "ReferralQuiz-module--option_selected--TWA-Y";
export var priceTxt = "ReferralQuiz-module--priceTxt--9HccT";
export var progressBar = "ReferralQuiz-module--progressBar--UmGC0";
export var progressHighBar = "ReferralQuiz-module--progressHighBar--6LEIp";
export var question = "ReferralQuiz-module--question---hDNe";
export var resultCon = "ReferralQuiz-module--resultCon--EKL6n";
export var resultConTxt = "ReferralQuiz-module--resultConTxt--jhRUu";
export var resultConTxt1 = "ReferralQuiz-module--resultConTxt1--ZbPUE";
export var resultWarn = "ReferralQuiz-module--resultWarn--gjlYL";
export var result_heading = "ReferralQuiz-module--result_heading--RRbLW";
export var result_p = "ReferralQuiz-module--result_p--2a7tH";
export var strikRs = "ReferralQuiz-module--strikRs--rpmN0";
export var submitBtn = "ReferralQuiz-module--submitBtn--UEq-K";
export var text1 = "ReferralQuiz-module--text1--tyFRh";
export var text2 = "ReferralQuiz-module--text2--bxDWU";
export var title1 = "ReferralQuiz-module--title1--Dsrvo";
export var title2 = "ReferralQuiz-module--title2--0GDaW";
export var treated = "ReferralQuiz-module--treated--bUOCh";
export var treated2 = "ReferralQuiz-module--treated2--aZQqR";
export var userName = "ReferralQuiz-module--userName--1ieEh";
export var webDetailsCon = "ReferralQuiz-module--webDetailsCon--No983";
export var webDr2Con = "ReferralQuiz-module--webDr2Con--cLydF";
export var webDrCon = "ReferralQuiz-module--webDrCon--qB2oB";
export var webiCon = "ReferralQuiz-module--webiCon--TpuKi";
export var webiConn = "ReferralQuiz-module--webiConn--cNgVv";
export var webiDetailImg = "ReferralQuiz-module--webiDetailImg--lNIcK";
export var webiTit = "ReferralQuiz-module--webiTit--hNZGc";
export var webiTit2 = "ReferralQuiz-module--webiTit2--Fj2Qx";
export var webiTxt1 = "ReferralQuiz-module--webiTxt1--1i0ML";
export var webititle = "ReferralQuiz-module--webititle--jbmLO";
export var webititle2 = "ReferralQuiz-module--webititle2--pKwBL";
export var whiteFontArrow = "ReferralQuiz-module--whiteFontArrow--bBDdB";
export var yoe = "ReferralQuiz-module--yoe---X9Lz";